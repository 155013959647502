$positive: #02e8a8;
$negative: #e5a10f;

.counter {

  display: inline-block;
  position: relative;
  border-radius: .2em;

  span {
    display: inline !important;
  }

  &.bold {
    font-weight: bold;
  }

  transition: color .5s ease-in, background-color .5s ease-in, text-shadow .5s ease-in;
  &.changing {
    &.positive {
      color: $positive !important;
      text-shadow: 0 0 10px rgba($positive,1);
    }
    &.negative {
      color: $negative !important;
      text-shadow: 0 0 10px rgba($negative,1);
    }
  }
  .counter-amount {
    position: relative;
  }
  &.stable {
  }

}