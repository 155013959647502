.App {
  margin: 3rem auto;
  max-width: 1250px;
  position: relative;
  text-align: center;
  z-index: 1;
  @media screen and (max-width: 1250px) {
    margin: 0 1rem;
  }
  h1 {
    opacity: .85;
    font-size: 25px;
    margin-top: 2em;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    @media screen and (max-width: 800px) {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
    .dot {
      opacity: .3;
      font-size: .9em;
    }
    .ext {
      opacity: .45;
      font-size: .85em;
    }
  }
}

footer {
  font-size: .7em;
  color: mix(white, #110d13, 50%);
  &:after {
    vertical-align: -.25rem;
    display: inline-block;
    content: '';
    height: 1.5rem;
    width: 1.5rem;
    margin-left: .1rem;
    background: url('./ajnaburn-flame.png');
    background-size: cover;
    opacity: .8;
  }
  a {
    text-decoration: none;
    color: rgb(161, 83, 190);
    transition: .25s ease-out;
    &:hover {
      color: rgb(192, 125, 217);
    }
  }
}