.chart-highlight {
  position: absolute;
  top: 3rem;
  left: 4rem;
  text-align: left;
  font-size: 1.5rem;
  @media screen and (max-width: 800px) {
    top: 0;
    left: 0;
  }
  .date {
    font-size: .7em;
    margin-bottom: .1em;
    color: mix(rgb(31, 11, 38), white, 40%);
    @media screen and (max-width: 800px) {
      font-size: .5em;
    }
  }
  .value {
    font-size: 1.5em;
    @media screen and (max-width: 800px) {
      font-size: .8em;
    }
  }
  .diff {
    position: absolute;
    font-size:1em;
    color: mix(rgb(31, 11, 38), white, 40%);
    margin-top: .1em;
    margin-left: .1em;
    @media screen and (max-width: 800px) {
      font-size:.6em;
    }
    &.is-burned {
      color: #e5a10f;
      &:before {
        content: '+';
        opacity: 60%;
        margin-right: .1em;
      }
    }
    &.is-today {
      //padding-left: 1em;
    }
    &.no-burn {
      margin-top: -.1em;
      .label {
        margin-left: 0;
      }
    }
    .fire-container {
      position: absolute;
      top: .35em;
      left: .25em;
      scale: 62%;
      @media screen and (max-width: 800px) {
        scale: 50%;
      }
    }
  }
  .label {
    color: mix(rgb(31, 11, 38), white, 55%) !important;
    font-size: .65em;
    margin-left: .5rem;
  }
}