@import '../../style/mixins.scss';

$borderRadius: .35em;
$height: 3rem;

.status-bar {
  max-width: 800px;
  margin: 2rem auto;
  @media screen and (max-width: 1250px) {
    margin-right: 2rem;
    margin-left: .5rem;
  }

  h3 {
    font-weight: normal;
    margin-bottom: .25em;
    font-size: .7em;
    text-align: left;
    padding-left: 1.2rem;
    opacity: .5;
  }
  .bar {
    box-sizing: content-box;
    position: relative;
    background-color: rgba(black, .125);
    border: 6px solid transparent;
    width: 100%;
    border-radius: .75em;
    height: $height;
    @include addShadow(rgba(black, .005));
    .pct {
      position: absolute;
      left: .4em;
      top: .2em;
      font-size: 2rem;
      font-weight: 500;
      .decimals {
        opacity: .9;
      }
      .counter-suffix {
        opacity: .65;
        font-size: .8em;
      }
    }
    &-pct {
      color: rgb(173, 76,211);
    }
    &-progress {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      background: linear-gradient(to right, rgb(113, 60, 134), rgb(182, 81, 222));
      transition: .25s ease-out;
      width: var(--width);
      height: 100%;
      border-radius: $borderRadius;
      overflow: hidden;
      &-pct {
        color: rgb(31, 11, 38);
      }
    }
    &-burn {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: var(--width);
      &-flame {
        position: absolute;
        right: 0;
        @media screen and (max-width: 1250px) {
          right: 4px;
        }
      }
    }
  }
  .values {
    display: flex;
    gap: .25rem;
    margin-top: .25em;
    text-align: left;
    padding-left: 1.2rem;
    align-items: flex-end;
    &-current {

    }
    &-separator {
      font-size: .8em;
      opacity: .3;
    }
    &-max {
      font-size: .7em;
      opacity: .65;
    }
  }
  .change {
    margin-top: .25rem;
    padding-left: 1.2rem;
    text-align: left;
    .fire-container {
      position: relative;
      width: 4rem;
      .fire {
        top: -.75rem;
        left: -1rem;
        scale: 30%;
      }
    }
    .counter {
      margin-right: .5rem;
      margin-left: 1.1rem;
    }
    .desc {
      font-size: .7em;
      opacity: .65;
    }
  }
  &.is-burning {
    position: relative;
    .bar-progress {
      background: linear-gradient(to right, rgb(130, 70, 154), rgb(173, 76,211) 90%, rgb(198, 94, 241) 100%);
    }
  }
  .bar-lighting {
    position: absolute;
    top: .25rem;
    right: .25rem;
    content: '';
    background: linear-gradient(to left, rgba(white, .35), rgba(white, 0));
    width: 40%;
    height: 2.5rem;
    border-radius: 0 $borderRadius $borderRadius 0;
    animation: LightPulse 1.2s ease-in infinite;
  }
}

@keyframes LightPulse {
  0% {
    opacity: 0;
    width: 40%;
  }
  50% {
    opacity: 1;
    width: 50%;
  }
  100% {
    opacity: 0;
    width: 40%;
  }
}