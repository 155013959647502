@mixin md-icon-size($size: 24px) {
  font-size: $size;
  height: $size;
  width: $size;
}

@mixin addShadow($color: #000000){
  box-shadow: 0 4px 10px -1px rgba($color, .25), 0 12px 12px 0 rgba($color, .05), 0 1px 40px 0 rgba($color, .1);
}

@mixin addGlow($color: #fff, $intensity: 1rem){
  box-shadow: 0 0 $intensity $color;
}

@mixin midColumnize($maxWidth: 55rem){
  max-width: $maxWidth;
  margin: 0 auto;
}

@mixin midColumnizeResponsive($maxWidth: 55rem){
  max-width: $maxWidth;
  margin: 0 auto;
  @media screen and (max-width: 900px){
    margin-right: 1rem;
    margin-left: 1rem;
  }
}


@mixin addShine($height: 5rem, $width: 100%, $rotation: 2deg, $start: 40%, $end: 100%){

  $shineColor1: rgba(255, 255, 255, 0);
  $shineColor2: rgba(255, 255, 255, .055);

  overflow: hidden;
  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    background: linear-gradient(180deg, $shineColor1 $start, $shineColor2 100%) $shineColor1;
    height: $height;
    width: $width;
    rotate: $rotation;
    transition: top ease-out .25s, rotate ease-out .25s;
    pointer-events: none;
  }
}