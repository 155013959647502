@font-face {
  font-family: OpenSans;
  src: url(../public/OpenSans-VariableFont_wdth,wght.ttf);
}
@font-face {
  font-family: Ubuntu;
  src: url(../public/Ubuntu-Regular.ttf);
}
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: Ubuntu;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #110d13;
  color: white;

  &:after {
    position: absolute;
    display: block;
    content: '';
    background: radial-gradient(ellipse at top, #2d2034, transparent 70%);
    height: 1000px;
    width: 100vw;
    top: 0;
    z-index: 0;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
