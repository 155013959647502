@use "sass:math";

@import '../../../style/mixins.scss';

$black: #111217;
$brown: #70392F;
$brownd: #612E25;
$yellow: rgb(214, 162, 234);
$orange: rgb(193, 100, 229);
$red: rgb(153, 67, 185);
$animationtime: 1.5s;

$height: 4rem;
$width: 4rem;

.fire {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -25%);
  height: $height;
  width: $width;
  //background-color:$red;
  .flames {
    position: absolute;
    bottom: 40%;
    left: 50%;
    width: 60%;
    height: 60%;
    //background-color:$red;
    transform: translateX(-50%) rotate(45deg);

    .flame {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0;
      height: 0;
      background-color: $yellow;
      border-radius: 50%;

      &:nth-child(2n + 1) {
        animation: flameodd $animationtime ease-in infinite;
      }

      &:nth-child(2n) {
        animation: flameeven $animationtime ease-in infinite;
      }

      &:nth-child(1) {
        animation-delay: 0s;
      }

      &:nth-child(2) {
        animation-delay: #{math.div($animationtime,4)};
      }

      &:nth-child(3) {
        animation-delay: #{math.div($animationtime,2)};
      }

      &:nth-child(4) {
        animation-delay: #{math.div($animationtime,4) * 3};
      }
    }
  }
}

@keyframes flameodd {
  0%, 100% {
    width: 0;
    height: 0;
  }
  25% {
    width: 100%;
    height: 100%;
  }
  0% {
    @include addGlow(white);
    background-color: white;
    z-index: 1000000;
  }
  30% {
    @include addGlow($yellow);
    background-color: $yellow;
    z-index: 1000000;
  }
  40% {
    @include addGlow($orange);
    background-color: $orange;
    z-index: 1000000;
  }
  100% {
    @include addGlow($red);
    background-color: $red;
    z-index: -10;
  }
  0% {
    right: 0;
    bottom: 0;
  }
  25% {
    right: 1%;
    bottom: 2%;
  }
  100% {
    right: 150%;
    bottom: 170%;
  }
}

@keyframes flameeven {
  0%, 100% {
    width: 0;
    height: 0;
  }
  25% {
    width: 100%;
    height: 100%;
  }
  0% {
    background-color: $yellow;
    z-index: 1000000;
  }
  40% {
    background-color: $orange;
    z-index: 1000000;
  }
  100% {
    background-color: $red;
    z-index: -10;
  }
  0% {
    right: 0;
    bottom: 0;
  }
  25% {
    right: 2%;
    bottom: 1%;
  }
  100% {
    right: 170%;
    bottom: 150%;
  }
}